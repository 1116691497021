<template>
  <div class="footer">
    <div class="lay">
      <div class="footer-item">
        <img src="../../assets/logoImage/new-logo-white.png" alt="logo..."/>
        <div class="footer-children">
          <p class="footer-One">Quick connect:</p>
          <p class="footer-con content" @click="goBack(1)">Get to know us again</p>
          <p class="peter content" @click="goBack(2)">Download data and code</p>
          <p class="peter content" @click="goBack(3)">Try automatic cell recognition</p>
        </div>
        <div class="footer-children">
          <p class="footer-One">R & D unit:</p>
          <p class="footer-con">Zhejiang University</p>
          <p class="peter">Nanjing Agricultural University</p>
          <p class="peter">Zhejiang Ruiyun Technology Co.Ltd.</p>
        </div>
        <!--          <div class="footer-children">-->
        <!--            <p class="footer-p1" @click="$router.push('/login')">Login</p>-->
        <!--            <p class="footer-p2 color1"><img src="../../assets/promptImage/mailbox.svg" alt="mailbox..."/>Contact us:-->
        <!--              xxx@zju.cn</p>-->
        <!--            <p class="footer-p2"><img src="../../assets/promptImage/URL.svg" alt="URL...">Cooperation: bd@mockingbot.com-->
        <!--            </p>-->
        <!--          </div>-->
      </div>
      <div class="TheEnd">
        浙ICP备2021036967号-1&emsp;|&emsp; &copy;2021 Plantorganelle hunter-<span class="span"
                                                                           @click="$router.push('/power')">Terms of Use</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    goBack(n){
      this.$emit('goBack',n);
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  height:270px;
  min-width: 1200px;
  background-color: #424242;
  .footer-item{
    height: 208px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;
    .footer-children{
      width: 260px;
      height: 130px;
      font-size: 14px;
      .footer-One{
        font-size: 16px;
      }
      .footer-con{
        margin-top: 20px;
      }
      .peter{
        margin-top: 10px;
      }
      .content:hover{
        cursor: pointer;
        text-decoration: underline;
      }
      .footer-p1{
        width:36px;
        cursor: pointer;
        margin-left:6px;
        border-bottom: white 1px solid;
        height: 20px;
      }
      .footer-p2{
        display: flex;
        align-items: center;
      }
      .color1{
        margin-top:20px;
      }
    }
  }
  .TheEnd{
    color: white;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    .span:hover{
      cursor: pointer;
      text-decoration:underline;
    }
  }
}
</style>
