<template>
  <div class="ExcellentBoy">
    <!---------------------------------------------------------  header部分-------------------------------------->
    <div class="header">
      <div class="header-children">
        <img class="logo" src="../../assets/logoImage/new-logo-green.png" alt=""/>
        <div class="header-item">
          <p v-for="(str,index) in HeaderNav" :key="index" @click="tabNav(index)" v-text="str"
             :class="active===index?'activeClass':''"></p>
        </div>
      </div>
    </div>
    <!-- home 部分 -->
    <div id="Home"></div>
    <HomeBanner @tryItClick="tryItClick"/>
    <!-- upload 部分 ----上传图片识别部分-->
    <div id="Upload"></div>
    <UploadFile/>
    <!-- about 部分 -->
    <div id="About"></div>
    <AboutBanner />
    <!-- dataset部分  -->
    <div id="Dataset"></div>
    <DatasetForm/>
    <HowToCite />
    <div id="Members"></div>
    <MembersIntroduce />
    <FooterEnd @goBack="goBack" />
  </div>
</template>
<script>
import HomeBanner from '@/components/HomeBanner';
import UploadFile from '@/components/UploadFile';
import DatasetForm from '@/components/DatasetForm';
import AboutBanner from '@/components/AboutBanner';
import HowToCite from '@/components/HowToCite';
import MembersIntroduce from '@/components/MembersIntroduce';
import FooterEnd from '@/components/FooterEnd';
export default {
  name: "Cell-recognition",
  components: {
    HomeBanner,
    UploadFile,
    DatasetForm,
    AboutBanner,
    HowToCite,
    MembersIntroduce,
    FooterEnd
  },
  data() {
    return {
      //tab数据
      HeaderNav: [
        'Home',
        'Upload',
        'About',
        'Dataset',
        'Members'
      ],
      //tab点击控制
      active: 0,
    }
  },
  mounted() {
    //监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
  },
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    //滚动监听器
    onScroll() {
      // //获取所有锚点元素
      const navContents = document.querySelectorAll('.EmotionBoy')
      const ScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if ((ScrollTop - navContents[0].offsetTop >= -830) && (ScrollTop - navContents[0].offsetTop <= 200)) {
        this.active = 0
      }
      if ((ScrollTop - navContents[1].offsetTop >= -1153) && (ScrollTop - navContents[1].offsetTop <= 269)) {
        this.active = 1
      }
      if ((ScrollTop - navContents[2].offsetTop >= -734) && (ScrollTop - navContents[2].offsetTop <= 653)) {
        this.active = 2
      }
      if ((ScrollTop - navContents[3].offsetTop >= -1428) && (ScrollTop - navContents[3].offsetTop <= 653)) {
        this.active = 3
      }
      if (ScrollTop - navContents[4].offsetTop >= -1370) {
        this.active = 4
      }
    },
    //tab选项
    tabNav(index) {
      this.active = index
      index === 0 ? document.querySelector('#Home').scrollIntoView(true) :
          index === 1 ? document.querySelector('#Upload').scrollIntoView(true) :
              index === 2 ? document.querySelector('#About').scrollIntoView(true) :
                  index === 3 ? document.querySelector('#Dataset').scrollIntoView(true) :
                      document.querySelector('#Members').scrollIntoView(true)
    },
    //footer跳转
    goBack(num) {
      num === 1 ? document.querySelector('#About').scrollIntoView(true) :
          num === 2 ? document.querySelector('#Dataset').scrollIntoView(true) :
              document.querySelector('#Upload').scrollIntoView(true)
    },
    //点击上传图片
    tryItClick() {
      document.querySelector('#Upload').scrollIntoView(true)
    },
  },
  filters: {
    //图片路径拼接过滤器
    tao(value) {
      return require('../../assets/bannerImage/' + value)
    },
  },
}
</script>

<style scoped lang="scss">
@import "Cell";
</style>




