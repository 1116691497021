<template>
  <div class="banner">
    <div>
      <div class="lay">
        <div class="Come-on">
          <!-- 轮播图上字体部分-->
          <div class="banner-Welcome">
            <!--      中间文字内容-->
            <div class="banner-center">
              <h2>Welcome to Plantorganelle hunter</h2>
              <div class="banner-text">
                <p>A tool to automatically identify plant chloroplast, mitochondria, nucleus,</p>
                <p>and vacuole from TEM data and provide quantitatively evaluation about shape, </p>
                <p>electron-density and area per organelle.</p>
              </div>
              <button class="banner-button EmotionBoy" @click="tryItClick">Try it-click here to upload images</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    tryItClick(){
      this.$emit('tryItClick',true);
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  min-width: 1200px;
  height: 100vh;
  background: url("../../assets/bannerImage/banner1.jpg") no-repeat 0 0/cover;
  .Come-on {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .banner-Welcome {
      height: 500px;
      min-width: 1200px;
      z-index: 100;
      margin: auto;
      text-align: center;
    }

    .banner-left {
      color: #a9afa0;
      top: 60%;
      left: 100px;
      cursor: pointer;
      font-size: 50px;
      position: absolute;
    }

    .banner-right {
      color: #a9afa0;
      top: 60%;
      right: 100px;
      cursor: pointer;
      font-size: 50px;
      position: absolute;
    }

    .banner-center {
      color: #ffffff;
      margin-top: 150px;

      h2 {
        font-family: Cemter;
        font-size: 50px;
      }

      .banner-text {
        font-size: 34px;
        margin-top: 80px;
      }

      .banner-button {
        width: 600px;
        height: 90px;
        color: white;
        font-family: Emotion;
        cursor: pointer;
        font-size: 30px;
        background: rgba(255, 255, 255, .4);
        border: #ffffff 2px solid;
        margin-top: 60px;
      }
    }
  }

}
</style>
