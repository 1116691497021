<template>
  <div class="Upload">
    <div class="lay">
      <div class="Upload-try">
        <h4>Upload</h4>
        <!--      步骤条-->
        <el-steps align-center class="Upload-steps" :active="activeSteps">
          <el-step title="Upload"></el-step>
          <el-step title="Identify"></el-step>
          <el-step title="Result"></el-step>
        </el-steps>
        <div class="describe">
          <p :class="activeSteps===3?'EmotionBoy color':'EmotionBoy'" v-text="uploadText"></p>
          <div class="new-div">
            <p>Before starting,  you need to read this guide and can get some test images here：</p>
            <p @click="$router.push('/english-guide')">English guide</p>
            <p @click="$router.push('/chinese-instructions')">中文使用说明</p>
          </div>
        </div>
        <div>
          <div class="Click-item" v-show="flag===1">
            <div class="upload-left">
              <el-upload
                  v-show="uploadFlag===0"
                  class="Upload-Click"
                  :action="uploadFileUrl"
                  :before-upload="handleBeforeUpload"
                  :file-list="fileList"
                  :limit="limit"
                  ref="upload"
                  accept=".jpg,.jpeg"
                  :on-change="imgSaveToUrl"
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  multiple
              >
                <button>+</button>
                <div slot="tip" class="el-upload__tip Upload-p">
                  Click here to upload the picture,<br/>
                  the format is .jpg，.jpeg，less then {{ fileSize }}M.
                </div>
              </el-upload>
              <div class="Upload-Click" v-show="uploadFlag===1">
                <div class="upload-img-block">
                  <button class="upload-img-button" @click="rollbackUpload">x</button>
                  <img :src="uploadImg" alt="loading..."/>
                </div>
              </div>
            </div>
            <div class="item-children">
              <el-form :model="Form" :rules="rulesOne" ref="ValidateForm" label-width="110px" class="demo-ruleForm">
                <el-form-item prop="Name" label-width="91px">
                  <div class="form-div">
                    <label class="label" slot="label">Your name:</label>
                    <el-input class="upload-input-edit input1" :maxLength="6" type="text"
                              v-model="Form.Name"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="Email" label-width="91px">
                  <div class="form-div">
                    <label class="label" slot="label">Your email:</label>
                    <el-input class="upload-input-edit input1" type="text" v-model="Form.Email"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="Organization" label-width="105px">
                  <div class="form-div">
                    <label class="labelOne" slot="label">Organization:</label>
                    <el-input class="upload-input-edit" type="text" v-model="Form.Organization"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="Department" label-width="100px">
                  <div class="form-div">
                    <label class="labelTwo" slot="label">Department:</label>
                    <el-input class="upload-input-edit" type="text" v-model="Form.Department"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="Country" label-width="80px">
                  <div class="form-div">
                    <label class="labelThree" slot="label">Country:</label>
                    <el-input class="upload-input-edit" type="text" v-model="Form.Country"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="ruleLength" label-width="100px">
                  <div class="form-div">
                    <label class="labelFour label" slot="label">pixel（px）:</label>
                    <el-input class="upload-input-edit" type="text" v-model="Form.ruleLength"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="rule" label-width="100px">
                  <div class="form-div">
                    <label class="labelFour label" slot="label">scale（nm）:</label>
                    <el-input class="upload-input-edit" type="text" v-model="Form.rule"></el-input>
                  </div>
                </el-form-item>
              </el-form>
<!--              <p class="click-page" @click="$router.push('/scaleBar')">Click here to see how to measure the scale-->
<!--                bar.</p>-->
            </div>
          </div>
          <div v-show="flag===2">
            <div class="upload-result-size">
              <div id="uploadLoading">
                <el-image class="upload-result-img" :src="uploadImg">
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="Upload-p-One">
          <div style="height:100px">
            <el-button v-if="uploadText!=='This process may take 1-2 minutes, please be patient.'"
                       @click="uploadIdentifying('ValidateForm')" :class="UploadClass"
                       :disabled="uploadDisabled"
                       v-text="uploadIdentify"></el-button>
            <p v-else>Identifying</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      //步骤条
      activeSteps: 1,
      //upload text显示变化
      uploadText: 'You can submit your personal information to the administrator and upload pictures at the same time, the recognition results will appear here.',
      //控制，模态框显示内容
      flag: 1,
      //控制显示
      uploadFlag: 0,
      //上传的图片请求地址
      uploadFileUrl: "none",
      //文件列表
      fileList: [],
      //图片地址存放
      fileListF: {Image: ''},
      //上传文件数量限制
      limit: 1,
      // 单个上传文件大小限制 MB
      fileSize: 10,
      // 允许上传的文件类型, 例如["pdf"]
      fileType: ["jpg", "jpeg"],
      //上传照片
      uploadImg: '',
      //upload表单数据
      Form: {
        Name: '',
        Email: '',
        Organization: '',
        Department: '',
        Country: '',
        rule: '',
        ruleLength: ''
      },
      //upload表单验证
      rulesOne: {
        Name: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        Email: [
          {required: true, message: 'This item cannot be empty', trigger: 'blur'},
          {type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change']}],
        rule: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        ruleLength: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}]
      },
      //识别button样式控制
      UploadClass: 'upload-p-button-fz',
      //识别显示状态
      uploadIdentify: 'Submit',
      //提示
      notifyPromise: Promise.resolve(),
      //禁用控制
      uploadDisabled: true,
      //识别id
      identifyId:null,
      //识别状态
      stateOne: 0,
      closeTimeout: false,
      timeoutId: null,
      loading:null
    }
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          return fileExtension && fileExtension.indexOf(type) > -1;

        });
        if (!isTypeOk) {
          this.warningNotify(`图片格式不正确，请上传${this.fileType.join("/")}格式图片！`);
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        // KB
        const fileSize = file.size / Math.pow(1024, 2);
        const isLt = fileSize < this.fileSize;
        if (!isLt) {
          this.warningNotify(`上传图片大小不能超过5M!`);
          return false;
        }
      }
      // 开始上传
      this.loading = this.$loading({
        lock: true,
        text: "上传中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.uploadSuccess({status: 200})
      return false;
    },
    // 文件个数超出限制
    handleExceed() {
      this.$message.warning(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error(`上传失败[${err}], 请重试!`);
      this.loading.close();
    },
    // 上传成功回调
    uploadSuccess(res) {
      if (res.status === 200) {
        this.uploadFlag = 1;
        this.activeSteps = 2;
        this.uploadDisabled = false;
        this.UploadClass = 'upload-p-button'
        //this.uploadText = 'If you want to change one,you can click on the picture to change';
        this.$message.success("上传成功");
        this.loading.close();
      } else {
        this.handleUploadError(res.message);
      }
    },
    //警告
    warningNotify(msg) {
      let _this = this;
      this.notifyPromise = this.notifyPromise.then(_this.$nextTick).then(() => {
        _this.$notify({
          type: 'warning',
          title: '警告',
          message: msg,
          duration: 6000
        });
      });
    },
    //识别事件
    uploadIdentifying(ValidateForm) {
      if (this.activeSteps === 3) {
        this.rollbackUpload();
        return;
      }
      this.$refs[ValidateForm].validate(async (valid) => {
        if (valid) {
          this.flag = 2;
          // this.uploadText = 'Recognizing,please wait...';
          this.uploadText = 'This process may take 1-2 minutes, please be patient.';
          this.uploadIdentify = 'Identifying';
          this.uploadDisabled = true;
          this.UploadClass = 'upload-p-button-fz';
          this.loading = this.$loading({
            lock: true,
            target: document.getElementById('uploadLoading'),
            text: 'Loading...',
            spinner: 'el-icon-loading',
            background: 'rgba(255,255,255, 0.6)'
          });
          await this.UploadSubmitForm();
          this.setPositionInterval();
        }
      })
    },
    setPositionInterval(){
      this.timeoutId = setTimeout(async () => {
        if (this.closeTimeout) {
          clearTimeout(this.timeoutId);
          this.timeoutId=null;
          this.closeTimeout = false;
          return;
        }
        await this.axios.get(`/api/analysis/${this.identifyId}`).then(data => {
          const {
            data: {
              data: {
                state,
                outPath
              }
            }
          } = data;
          this.stateOne = state;
          if (state === 2 || state === 3) {
            this.closeTimeout = true;
            this.stateOne = 0;
            this.activeSteps = 3;
            this.uploadText = 'Identification of failure ！';
            this.uploadDisabled = false;
            this.UploadClass = 'upload-p-button';
            this.uploadIdentify = 'try one more';
            this.loading.close();
            this.$message.warning('识别失败！');
            return;
          }
          if(outPath || state===1){
            this.closeTimeout = true;
            this.stateOne = 0;
            this.activeSteps = 3;
            this.uploadText = 'Finished, the result will be sent to your email, please check';
            this.uploadDisabled = false;
            this.UploadClass = 'upload-p-button';
            this.uploadIdentify = 'try one more';
            this.uploadImg = `https://www.cropopen.com:8009/images/${outPath}`;
            this.loading.close();
          }
        })
        this.setPositionInterval();
      }, 1000);
    },
    //点击红×回滚到上传文件
    rollbackUpload() {
      this.uploadIdentify = 'Submit';
      this.uploadFlag = 0;
      this.activeSteps = 1;
      this.UploadClass = 'upload-p-button-fz';
      this.fileList = [];
      this.fileListF.Image = '';
      this.uploadText = 'You can submit your personal information to the administrator and upload pictures at the same time, the recognition results will appear here.';
      this.uploadDisabled = true;
      this.stateOne = 0;
      this.flag = 1;
      this.Form = {};
      this.uploadImg = '';
      this.identifyId = null;
    },
    async UploadSubmitForm() {
      let formData = new FormData();
      const {Image} = this.fileListF;
      const {
        Name,
        Email,
        Organization,
        Department,
        Country,
        rule,
        ruleLength
      } = this.Form;
      formData.append('Image', Image);
      formData.append('Name', Name);
      formData.append('Email', Email);
      formData.append('Organization', Organization);
      formData.append('Department', Department);
      formData.append('Country', Country);
      formData.append('rule', rule);
      formData.append('ruleLength', ruleLength);
      await this.axios.post('/api/analysis', formData).then(data => {
        const {
          data: {
            data: {
              analysisId
            }
          }
        } = data;
        this.identifyId = analysisId;
        setTimeout(() => {
          this.$refs.upload.clearFiles()
        }, 100)
      })
    },
    //生成图片
    imgSaveToUrl(file) {
      this.fileListF.Image = file.raw
      let URL = window.URL || window.webkitURL;
      this.uploadImg = URL.createObjectURL(file.raw)
    }
  }
}
</script>

<style scoped lang="scss">
.Upload {
  height: 100vh;
  display: flex;
  align-items: center;

  .lay {
    width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
  }

  .upload-block {
    display: block;
  }

  .upload-demo {
    display: none;
  }

  h4 {
    text-align: center;
    font-size: 42px;
    margin-top: 120px;
    font-family: Cemter;
  }

  .Upload-steps {
    width: 640px;
    margin: 30px auto;

    ::v-deep .is-horizontal {
      .el-step__head.is-finish {
        .el-step__line {
          // 当前步骤数横线样式设置
          .el-step__line-inner {
            width: 100% !important;
            border: #334a04 1px solid !important;
          }
        }

        // 当前步骤数圆圈样式设置
        .el-step__icon.is-text {
          background: #334a04;
          border: 0;
          color: #334a04;
        }
      }

      .el-step__title.is-finish {
        color: #334a04;
      }

      .el-step__title {
        font-family: Rule;
        font-size: 30px;
      }
    }
  }

  .describe {
    .EmotionBoy {
      font-size: 18px;
      font-family: Regular;
    }
    .new-div{
      display: flex;
      margin: 15px 0 30px 0;
      p{
        font-family: Regular;
        font-size: 18px;
      }
      p:nth-child(2),
      p:nth-child(3){
        color: #295F10;
        cursor: pointer;
        margin-left: 10px;
        border-bottom: 1px #295F10 solid;
      }
    }
    .color {
      font-family: Medium;
      color: #295F10;
    }
  }

  .Click-item {
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload-left{
      margin-right: 15px;
    }
    .Upload-Click {
      width: 500px;
      height: 360px;
      margin: 28px auto;
      background-color: #f3f6f2;
      border: #91af83 2px solid;

      button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 0;
        color: white;
        cursor: pointer;
        margin: 110px 0 0 200px;
        font-size: 60px;
        background-color: #295f10;
      }

      .Upload-p {
        text-align: center;
        font-size: 20px;
        font-family: Rule;
        color: #8c8c8c;
        line-height: 40px;
        margin-top: 30px;
      }

      .upload-img-block {
        width:500px;
        height: 360px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .upload-img-button {
          width: 30px;
          height: 30px;
          top: -126px;
          right: -16px;
          font-size: 20px;
          background-color: red;
          position: absolute;
        }
      }
    }

    .item-children {
      width: 580px;
      height: 360px;
      margin-left: 15px;
      font-family: Rule;

      .upload-input-edit ::v-deep {
        .el-input__inner {
          height: 23px;
          border: 0;
          border-radius: 0;
        }
      }
      ::v-deep .el-form-item__error{
        margin-left: 30px;
      }
      .el-form-item {
        height: 32px;
        border-bottom: #b3c7ac 1px solid;

        .form-div {
          height:23px;
          display: flex;

          .label {
            width: 100px;
            margin-left: -85px;
          }

          .labelOne {
            margin-left: -96px;
          }

          .labelTwo {
            margin-left: -90px;
          }

          .labelThree {
            margin-left: -70px;
          }

          .labelFour {
            margin-left: -90px;
          }

          .input1 {
            height: 16px;
            margin-left: -20px;
          }

          .el-input {
            font-size: 20px;
            font-family: Regular;
          }

          .label::before {
            content: '*';
            color: red;
          }
        }

        .upload-button-edit {
          width: 160px;
          height: 50px;
          border: 0;
          color: white;
          border-radius: 0;
          font-size: 20px;
          margin-left: -100px;
          background-color: #245f07;
        }

        .Jump-p {
          width: 80px;
          color: #a9a9a9;
          cursor: pointer;
          margin-left: 125px;
          height: 30px;
          border-bottom: #a9a9a9 1px solid;
        }
      }
      .upload-else1 {
        height: 430px;
        text-align: center;

        img {
          margin-top: 60px;
        }

        .upload-else-p1 {
          font-size: 20px;
        }

        .upload-else-p2 {
          color: #a3a3a3;
        }

        .upload-else-p3 {
          width: 75px;
          color: #a3a3a3;
          cursor: pointer;
          margin: auto;
          border-bottom: #979797 1px solid;
        }
      }

      .click-page {
        width: 298px;
        line-height: 20px;
        color: #295F10;
        cursor: pointer;
        font-size: 14px;
        border-bottom: #295F10 1px solid;
      }
    }
  }

  .upload-result-size {
    height: 439px;
    display: flex;
    align-items: center;
    justify-content: center;

    #uploadLoading {
      width: 600px;
      height: 439px;
      background-color: #f4f6f3;
      border: #ccdcc9 1px solid;

      .upload-result-img {
        width: 600px;
        height: 439px;
      }
    }

    .upload-org {
      width: 500px;
      height: 439px;
      display: flex;
      margin-left: 6px;
      margin-top: -2px;
      justify-content: space-between;

      .upload-org-item {
        width: 245px;
        height: 439px;

        p {
          height: 87.4px;
          margin: 0;
          padding-left: 60px;
          text-align: left;
          font-size: 18px;
          border-bottom: #c1d2bc 1px solid;
          line-height: 86px;
        }

        p:nth-child(1) {
          border-bottom: 0;
          font-size: 21px;
          background-color: #f4f6f3;
        }
      }
    }
  }

  .Upload-p-One {
    font-size: 25px;
    margin-top: 40px;
    text-align: center;

    .upload-p-button {
      color: white;
      width: 200px;
      font-size: 23px;
      height: 60px;
      border-radius: 0;
      border: 0;
      font-family: Cemter;
      background-color: #295f11;
    }

    .upload-p-button-fz {
      color: white;
      width: 200px;
      font-size: 23px;
      height: 60px;
      font-family: Cemter;
      border-radius: 0;
      border: 0;
      background-color: #888888;
    }

    p {
      font-size: 26px;
      font-family: Medium;
    }
  }
}
</style>
