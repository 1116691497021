<template>
  <div class="howToCite">
    <div class="lay">
      <div class="howToCite-dis">
        <div class="howToCite-dis-item">
          <div class="dis-item-box blue">
            <p>how to cite</p>
            <img src="../../assets/promptImage/instructions.svg" alt="Tip..."/>
          </div>
          <p class="dis-item-p">All submitted papers (or any publicly available text)
            that uses this tool of the Plantorganelle hunter of the
            database must cite the following paper.
          </p>
        </div>
        <div class="howToCite-dis-item">
          <div class="dis-item-box red">
            <p>Disclaimer!</p>
            <img src="../../assets/promptImage/warning.svg" alt="Tip..."/>
          </div>
          <p class="dis-item-p">The Plantorganelle hunter dataset is licensed under the
            Creative Commons Attribution- NonCommercial-
            ShareAlike 4.0 International (CC BY-NC-SA 4.0)
            license.Therefore,the data must not be used for
            commercial purposes.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "index"
}
</script>

<style scoped lang="scss">
.lay{
  width:1200px;
  min-width: 1200px;
  margin: 0 auto;
}
.howToCite{
  height: 500px;
  color: white;
  min-width: 1200px;
  background-color:#424242;
  .howToCite-dis{
    height: 500px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    .howToCite-dis-item{
      width: 560px;
      height:250px;
      .dis-item-box{
        width: 487px;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
          margin-right:-60px;
          margin-top: 30px;
        }
        p{
          font-size:32px;
          font-family: Cemter;
        }
      }
      .blue{
        border-bottom:#0da5ee 1px solid;
      }
      .red{
        border-bottom:#fa5151 1px solid;
      }
      .dis-item-p{
        color: #e0e0e0;
        font-size: 22px;
        margin-top: 20px;
        line-height: 40px;
      }
    }
  }
}
</style>
