<template>
  <div class="Projects">
    <div class="lay">
      <h4 class="h3">Members</h4>
      <div class="Projects-school" v-for="(str,index) in Projects" :key="index">
        <div class="Projects-img-logo">
          <img :class="index===1?'EmotionBoy':''" :src="str.schoolLog|boy" alt="Load failed..."/>
        </div>
        <div class="Projects-card">
          <div class="card-div" v-for="(item,index) in str.Str" :key="index">
            <el-card class="box-card" shadow="hover">
              <div class="Projects-img">
                <img :src="item.photo|girl" alt="Load failed..."/>
              </div>
              <div class="Projects-div-abs">
                <div class="abs-item" v-show="index===0||index===3">
                  <img src="../../assets/NobleIdentity/00-user.svg" alt="NobleIdentity..."/>
                  <span class="item-span" style="">associate professor</span>
                </div>
                <span class="span1" v-text="item.name"></span>
              </div>
              <span class="span2" v-text="item.age"></span><br/>
              <p class="span3 PSpan" v-text="item.sex"></p>
              <p class="span4 PSpan" v-text="item.job"></p>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      //学生介绍
      Projects: [
        {
          schoolLog: '00-logo.png', Str: [
            {
              photo: 'Zhejiang-university1.png',
              name: 'Xuping Feng',
              age: '',
              sex: 'Plant phenotyping',
              job: 'fengxp@zju.edu.cn'
            },
            {
              photo: 'Zhejiang-university2.png',
              name: 'Zeyu Yu',
              age: '',
              sex: 'Plant phenotyping',
              job: 'zy_yu@zju.edu.cn'
            },
            {
              photo: 'Zhejiang-university3.png',
              name: 'Guofeng Yang',
              age: '',
              sex: 'Plant phenotyping',
              job: 'yangguofeng@zju.edu.cn'
            }
          ]
        },
        {
          schoolLog: '01-logo.png', Str: [
            {
              photo: 'Nanjing-agricultural1.png',
              name: 'Feng Liu',
              age: '',
              sex: 'Plant Cell Biology',
              job: 'liufeng@njau.edu.cn'
            },
            {
              photo: 'Nanjing-agricultural2.png',
              name: 'Liting Chen',
              age: '',
              sex: 'Plant Cell Biology',
              job: '10118114@njau.edu.cn'
            },
            {
              photo: 'Nanjing-agricultural3.png',
              name: 'Xinran Zhou',
              age: '',
              sex: 'Plant Cell Biology',
              job: '10118116@njau.edu.cn'
            }
          ]
        }
      ],
    }
  },
  filters:{
    //图片路径拼接过滤器
    boy(value) {
      return require('../../assets/schoolLogo/' + value)
    },
    //图片路径拼接过滤器
    girl(value) {
      return require('../../assets/photo/' + value)
    },
  }
}
</script>

<style scoped lang="scss">
.Projects{
  height:1200px;
  padding-top: 100px;
  min-width: 1200px;
  background: url("../../assets/memberImage/00-member.png") no-repeat 0 0/cover;
  .h3{
    text-align: center;
    height:120px;
    font-family: Cemter;
    line-height:120px;
    font-size: 42px;
  }
  .Projects-school{
    .Projects-img-logo{
      height:160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .Projects-card{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .card-div{
        width: 360px;
        height:346px;
        .box-card{
          cursor: pointer;
          width: 360px;
          height:340px;
          border: 0;
          text-align: center;
          background-color:#f3f6f2;
          &:hover{
            border-bottom: #3f871c 6px solid;
          }
          .Projects-div-abs{
            display: flex;
            justify-content: center;
            align-items: center;
            .abs-item{
              width:30px;
              height:30px;
              margin-bottom: -36px;
              margin-right: -15px;
              position: relative;
              .item-span{
                position:absolute;
                width:200px;
                bottom:-8px;
                display: none;
                left:-100px;
              }
              &:hover .item-span{
                display: block;
              }
            }
          }
          .Projects-img{
            width: 124px;
            height: 164px;
            margin: 0 auto;
            img{
              width: 124px;
              height: 164px;
            }
          }
          span{
            margin-left: 15px;
            display: block;
            line-height:3px;
          }
          .span1{
            margin-top:30px;
            font-size: 20px;
            font-family: Cemter;
          }
          .span2{
            font-size: 14px;
            font-weight: bold;
          }
          .span3{
            font-size: 14px;
            margin-top: 20px;
            margin-left: 15px;
          }
          .span4{
            font-size: 14px;
            margin-left: 15px;
          }
        }
      }

    }
  }
}
</style>
