<template>
  <div class="about">
    <div class="layGet">
      <div class="EmotionBoy" style="height:400px;margin-bottom:-400px"></div>
      <el-carousel class="banner-Image" ref="carousel" direction="vertical" height="100vh" arrow="never">
        <el-carousel-item v-for="(item,index) in aboutText" :key="index">
          <img class="aboutImage" :src="item.AboutImage|Emotion" alt="loading..."/>
          <div class="about-father">
            <div class="about-tool">
              <div v-show="item.judge===1">
                <p class="h3"><span v-text="item.two"></span>{{ item.three }}</p>
                <p class="h1" v-text="item.four"></p>
                <p class="h2" v-text="item.five"></p>
              </div>
              <div v-show="item.judge===2">
                <p class="h4 h5" v-text="item.two"></p>
                <p class="h5" v-text="item.three"></p>
              </div>
              <div v-show="item.judge===3">
                <p class="h5" v-text="item.two"></p>
                <p class="h5" v-text="item.three"></p>
                <p class="h5" v-text="item.four"></p>
              </div>
              <div v-show="item.judge===4">
                <p class="h5" v-text="item.two"></p>
                <p class="h5" v-text="item.three"></p>
                <p class="h5" v-text="item.four"></p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      //轮播字体
      aboutText: [
        {
          judge: 1,
          AboutImage: '00-about.png',
          two: 'P',
          three: 'lantorganellehunter',
          four: 'A tool to work with plant cellular electron',
          five: 'microscope images to quantify the phenotype of organelles.'
        },
        {
          judge: 2,
          AboutImage: '01-about.png',
          two: 'AI system for',
          three: 'quantitative analysis plant cell organelles morphology .'
        },
        {
          judge: 3,
          AboutImage: '02-about.png',
          two: 'The simplest way to use Plantorganellehunter',
          three: 'is to upload a jpg using the',
          four: 'upload button on the Upload page.'
        },
        {
          judge: 4,
          AboutImage: '03-about.png',
          two: 'This project is cooperated by',
          three: 'multidisciplinary teams from',
          four: 'Zhejiang University and Nanjing agriculture University.'
        }
      ],
    }
  },
  filters:{
    //图片路径拼接过滤器
    Emotion(value) {
      return require('../../assets/aboutImage/' + value)
    }
  }
}
</script>

<style scoped lang="scss">
.about {
  height: 100vh;
  width: 100%;
  padding-top: 60px;
  min-width: 1200px;

  .aboutImage {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .about-father {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    .about-tool {
      height: 500px;
      width: 100%;
      background-color: rgba(255, 255, 255, .6);
      position: absolute;
      color: black;
      display: flex;
      align-items: center;
      z-index: 10;

      div {
        min-width: 1200px;
        text-align: center;
        margin: 100px auto;
        .h3 {
          width: 700px;
          height: 80px;
          text-align: center;
          font-family: SemiBold;
          margin: auto;
          font-size: 66px;
          text-indent: 100px;
          position: relative;

          span {
            font-family: Regular;
            position: absolute;
            font-size: 220px;
            margin-top: 20px;
            text-indent: 0;
            left: -40px;
            top: -115px;
          }
        }

        .h1 {
          font-size: 34px;
          margin-top: 10px;
          font-family: Medium;
        }

        .h2 {
          font-size: 34px;
          margin-top: 6px;
          font-family: Medium;
        }

        .h5 {
          font-family: Medium;
          font-size: 42px;
        }
      }
    }
  }

}
</style>
