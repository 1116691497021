<template>
  <div class="Dataset">
    <div class="lay">
      <h4 class="Dataset-h4">Dataset</h4>
      <p class="Dataset-p">Our organelle segmentation network (OrgSegNet) works on 673 plant cell electron microscope
        images consists of 6371 organelles human-annotated collected from 13 plant species. Our TEM dataset contained
        3149 chloroplast, 1507 mitochondria, 123 nucleus, and 1592 vacuole annotations.
      <p class="Dataset-p alternative">Researchers can request access to this database by filling in and submitting the
        form below and reading and agreeing to the terms of use.</p>
      <div class="Dataset-div">
        <div class="Dataset-div-item">
          <p>20G</p>
          <p>Dataset size</p>
        </div>
        <div class="Dataset-div-item">
          <p>4 Categories</p>
          <p>Organelle</p>
        </div>
        <div class="Dataset-div-item">
          <p>673 TEMs</p>
          <p>Subcellular</p>
        </div>
      </div>
      <div class="form-weight">
        <el-form :hide-required-asterisk="true" :model="numberValidateForm" :rules="rules" ref="numberValidateForm"
                 label-width="100px" class="demo-ruleForm">
          <el-form-item prop="Name" label-width="110px">
            <label slot="label" class="label">
              Your name:</label>
            <el-input class="rule-input-edit" :maxlength="6" type="text" v-model="numberValidateForm.Name"></el-input>
          </el-form-item>
          <el-form-item prop="Email" label-width="110px">
            <label slot="label" class="label">
              Your email:</label>
            <el-input class="rule-input-edit" type="email" v-model="numberValidateForm.Email"></el-input>
          </el-form-item>
          <el-form-item prop="Organization" class="EmotionBoy" label-width="125px">
            <label slot="label" class="label">
              Organization:</label>
            <el-input class="rule-input-edit" :maxlength="20" type="text"
                      v-model="numberValidateForm.Organization"></el-input>
          </el-form-item>
          <el-form-item prop="Department" label-width="120px">
            <label slot="label" class="label">
              Department:</label>
            <el-input class="rule-input-edit" :maxlength="20" type="text"
                      v-model="numberValidateForm.Department"></el-input>
          </el-form-item>
          <el-form-item prop="Country" label-width="95px">
            <label slot="label" class="label">
              Country:</label>
            <el-input class="rule-input-edit" :maxlength="10" type="text"
                      v-model="numberValidateForm.Country"></el-input>
          </el-form-item>
          <el-form-item label-width="256px" prop="radio" style="border-bottom: 0">
            <label slot="label" class="label">
              Do you accept the data set <span class="item-label"
                                               @click="$router.push('/power')">terms</span>:</label>
            <el-radio-group v-model="numberValidateForm.radio" @change="changeHandler">
              <el-radio :class="numberValidateForm.radio==1?'rule-radio-edit':''" label="1">Yes</el-radio>
              <el-radio :class="numberValidateForm.radio==2?'rule-radio-edit':''" label="2">No</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="border-bottom: 0">
            <el-button :class="submit==='Submit'?'rule-button-edit':'rule-button-edit-states'" :disabled="disabled"
                       @click="submitForm('numberValidateForm')" v-text="submit"></el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="Dataset-Tip">
        <img :src="svgImage|Lion" alt="Tip..."/>
        <p class="Dataset-Tip-p" v-text="TipText"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      //表单
      numberValidateForm: {
        Name: '',
        Email: '',
        Organization: '',
        Department: '',
        Country: '',
        radio: 1
      },
      //表单验证
      rules: {
        Name: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        Email: [{required: true, message: 'This item cannot be empty', trigger: 'blur'},
          {type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change']}],
        Organization: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        Department: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        Country: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}],
        radio: [{required: true, message: 'This item cannot be empty', trigger: 'blur'}]
      },
      //提交状态变化
      submit: 'Submit',
      //submit禁用
      disabled: false,
      //事件提示
      TipText: 'Tip:Please make sure that the information you fill in is correct.After submission,the administrator will verify your\n' +
          'information and inform you of the download method by email',
      //svg状态变化
      svgImage: 'attention.svg'
    }
  },
  methods: {
    //监听radio变化
    changeHandler() {
      if (this.numberValidateForm.radio == 2) {
        this.submit = 'Unable to submit';
        this.disabled = true;
        this.svgImage = 'disagree.svg';
        this.TipText = 'You did not agree to the terms and cannot get the download address.'
      } else {
        this.submit = 'Submit';
        this.disabled = false;
        this.svgImage = 'attention.svg';
        this.TipText = 'Tip:Please make sure that the information you fill in is correct.After submission,the administrator will verify your\n\r' +
            'information and inform you of the download method by email'
      }
    },
    //about上传表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const {
            Name,
            Email,
            Organization,
            Department,
            Country
          } = this.numberValidateForm;
          let data = {
            Name,
            Email,
            Organization,
            Department,
            Country
          }
          this.axios.post('/api/data-request', data).then(data => {
            this.$message.success(`${data.data.message} ^.^`);
            this.submit = 'Submitted';
            this.disabled = true;
            this.numberValidateForm = {};
            this.svgImage = 'successful.svg';
            this.TipText = 'Has been successfully sent,please pay attention to the mailbox';
          });
        } else {
          this.submit = 'Submit';
          this.disabled = false;
          this.svgImage = 'attention.svg';
          this.TipText = 'Tip:Please make sure that the information you fill in is correct.After submission,the administrator will verify your\n' +
              'information and inform you of the download method by email'
        }
      });
    },
  },
  filters: {
    //图片路径拼接过滤器
    Lion(value) {
      return require('../../assets/promptImage/' + value)
    },
  }
}
</script>

<style scoped lang="scss">
.lay{
  width:1200px;
  min-width: 1200px;
  margin: 0 auto;
}
.Dataset {
  .Dataset-h4 {
    margin-top: 100px;
    height: 120px;
    text-align: center;
    font-size: 42px;
    font-family: Cemter;
    line-height: 120px;
  }

  .Dataset-p {
    color: #585858;
    font-size: 22px;
    font-family: Rule;
    line-height: 50px;
  }

  .alternative {
    margin-top: 20px;
  }

  .Dataset-div {
    height: 280px;
    display: flex;
    font-family: Cemter;
    align-items: center;
    justify-content: space-between;

    .Dataset-div-item {
      width: 386px;
      height: 170px;
      color: white;
      background-color: #62a355;

      p:nth-child(1) {
        font-size: 50px;
        margin-left: 20px;
        margin-top: 30px;
      }

      p:nth-child(2) {
        font-size: 30px;
        margin-left: 20px;
      }
    }
  }

  .form-weight {
    font-size: 20px;
    font-family: Rule;

    .rule-input-edit ::v-deep {
      .el-input__inner {
        border: 0;
        font-size: 30px;
        font-family: Regular;
        border-radius: 0;
      }
    }

    .el-form-item {
      border-bottom: #b3c7ac 1px solid;

      .label:before {
        content: '*';
        color: red;
        margin-left: 20px;
      }

      .item-label:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .rule-radio-edit ::v-deep {
      .el-radio__inner {
        border: #245f07 5px solid;
      }

      .el-radio__label {
        color: black;
      }
    }

    .rule-button-edit {
      width: 240px;
      height: 50px;
      border: 0;
      font-size: 25px;
      color: white;
      font-family: Cemter;
      border-radius: 0;
      margin-left: -80px;
      background-color: #245f07;
    }

    .rule-button-edit-states {
      width: 240px;
      height: 50px;
      border: 0;
      ont-family: Cemter;
      color: white;
      font-size: 25px;
      border-radius: 0;
      margin-left: -80px;
      background-color: #898989;
    }

    .rule-button-edit-states:hover {
      color: white;
      background-color: #898989;
    }
  }

  .Dataset-Tip {
    height: 77px;
    display: flex;
    margin-top: 60px;

    .Dataset-Tip-p {
      color: #585858;
      font-size: 20px;
      margin-left: 16px;
      margin-top: 0;
    }
  }
}
</style>
